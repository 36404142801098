import React, { Component } from "react";

export class testimonials extends Component {
  render() {
    return (
      <div id="testimonials">
        <div className="container">
          <div className="section-title text-center">
            <h2>Why Influx</h2>
          </div>
          <img
            src="img/team/01.jpg"
            className="img-responsive"
            alt="client service framework"
          />
          <div className="row testimonial text-center">
            <div className="section-title text-center">
              <h3>Our Search Process</h3>
            </div>
            <p>
              Executive Search process at Influx Sourcing follows a detailed,
              structured and result oriented approach. The process implies step
              by step method starting from understanding the client’s
              requirement to effective closure within the agreed timeline. The
              search process is detailed as:
            </p>
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-md-4">
                    <div className="testimonial">
                      <div className="testimonial-content">
                        <div className="testimonial-meta">{d.title} </div>
                        <p>{d.text}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <img
            src="img/csf.png"
            className="img-responsive"
            alt="client service framework"
          />
        </div>
      </div>
    );
  }
}

export default testimonials;
