import React, { Component } from "react";

export class Team extends Component {
  render() {
    return (
      <div id="team">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="img/team/03.jpg"
                className="img-responsive"
                alt=""
              />{" "}
              <img src="img/team/02.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Influx Consultants</h2>
                <p>
                  INFLUX SOURCING’s consultants are passionate about their work
                  and their clients. We pride ourselves on our collaborative
                  approach internally and the expert value we bring to the
                  companies and executives we represent.
                </p>
                <p>
                  INFLUX SOURCING team members hold the highest of ethical
                  standards, along with an unsurpassable level of personal
                  service, and an unmatched dedication to quality.
                </p>
                <div style={{ marginTop: 128}}></div>
                <p>
                  This philosophy and methodology are consistently demonstrated
                  in INFLUX SOURCING’s thorough and unparalleled researching
                  capability, the full utilization of firm resources on every
                  assignment, and the timeliness with which we complete our
                  assignments.
                </p>
                <p>
                  Every Consultant at INFLUX SOURCING has deep sector and
                  functional expertise .Our collaborative teaming results in
                  conversations that define the bigger picture –balanced with
                  client needs and objectives.
                </p>
                <p>
                  These strengths bring clarity to the complex challenge
                  confronting our clients: having the right talent, in the right
                  place, at the right time.
                </p>
              </div>
            </div>
          </div>
          {/* <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div> */}
        </div>
      </div>
    );
  }
}
export default Team;
