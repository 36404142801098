import React, { Component } from "react";

export class industries extends Component {
  render() {
    return (
      <div id="industries">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="img/portfolio/05-large.jpg"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Industries we serve</h2>
              </div>
              <p>
                {this.props.data ? this.props.data.paragraph : "loading..."}
              </p>
              <p>
                {this.props.data ? this.props.data.paragraph2 : "loading..."}
              </p>
              <p>
                {this.props.data ? this.props.data.paragraph3 : "loading..."}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default industries;
